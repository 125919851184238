import React, { useState, useContext } from 'react';
import { MutationResult, useMutation } from '@apollo/client';
import { PlantData, RoomType } from './models'
import { Card, CardBody, CardTitle, CardSubtitle, Progress, Button, Badge, ButtonGroup,
       } from 'reactstrap';
import { POSTPONE_WATERING, WATER_PLANT } from './queries'
import { usePlantsDispatch } from './PlantsDispatch'
import PlantsEdit from './PlantsEdit'
import styles from "./Plant.module.css"
import uiStyles from "../UI/UIElements.module.css"
import ErrorHandler from './ErrorHandler';
import fallback from './images/fallback_t.png'
import fallbackSad from './images/fallback_01_t.png'
import aloe from './images/aloe_t.png'
import aloeSad from './images/aloe_01_t.png'
import citrus from './images/citrus_t.png'
import citrusSad  from './images/citrus_01_t.png'
import peacyLily from './images/peace_lily_t.png'
import peacyLilySad from './images/peace_lily_01_t.png'
import ivy from './images/ivy_t.png'
import ivySad from './images/ivy_01_t.png'
import flamingoFlower from './images/flamingo_flower_t.png'
import flamingoFlowerSad from './images/flamingo_flower_01_t.png'
import dragonFruit from './images/dragon_fruit_t.png'
import dragonFruitSad from './images/dragon_fruit_01_t.png'
import arecaPalm from './images/areca_palm_t.png'
import arecaPalmSad from './images/areca_palm_01_t.png'
import calathea from './images/calathea_t.png'
import calatheaSad from './images/calathea_01_t.png'
import dracena from './images/dracena_t.png'
import dracenaSad from './images/dracena_01_t.png'
import kalanchoe from './images/kalanchoe_t.png'
import kalanchoeSad from './images/kalanchoe_01_t.png'
import mangave from './images/mangave_t.png'
import mangaveSad from './images/mangave_01_t.png'
import monstera from './images/monstera_t.png'
import monsteraSad from './images/monstera_01_t.png'
import parlorPalm from './images/parlor_palm_t.png'
import parlorPalmSad from './images/parlor_palm_01_t.png'
import peperomia from './images/peperomia_t.png'
import peperomiaSad from './images/peperomia_01_t.png'
import ananas from './images/ananas_t.png'
import ananasSad from './images/ananas_01_t.png'
import avocado from './images/avocado_t.png'
import avocadoSad from './images/avocado_01_t.png'
import happyCalla from './images/happy_calla_t.png'
import happyCallaSad from './images/happy_calla_01_t.png'
import hibiscus from './images/hibiscus_t.png'
import hibiscusSad from './images/hibiscus_01_t.png'
import spiderPlant from './images/spider_plant_t.png'
import spiderPlantSad from './images/spider_plant_01_t.png'
import tradescantia from './images/tradescantia_t.png'
import tradescantiaSad from './images/tradescantia_01_t.png'
import violet from './images/violet_t.png'
import violetSad from './images/violet_01_t.png'
import chineseMoneyPlant from './images/chinese_money_plant_t.png'
import chineseMoneyPlantSad from './images/chinese_money_plant_01_t.png'
import streptocarpus from './images/streptocarpus_t.png'
import streptocarpusSad from './images/streptocarpus_01_t.png'


import EditModal from './EditModal';
import RoomBadge from './RoomBadge';
import LoadingScreen from './LoadingScreen';
import UserContext from '../User/UserContext'


interface PlantProps extends PlantData { index: number, room?: RoomType }
interface WhenToWaterProps { daysUntilNextWatering: number }

interface PlantImageProps {
  scientificName: string;
  className?: string;
  needsWater?: boolean;
}

const getPlantImage = (scientificName: string, needsWater: boolean) => {
  switch (scientificName.toLowerCase()) {
    case 'aloe vera':
      return needsWater ? aloeSad : aloe;
    case 'citrus':
      return needsWater ? citrusSad : citrus;
    case 'spathiphyllum':
      return needsWater ? peacyLilySad : peacyLily;
    case 'hedera helix':
      return needsWater ? ivySad : ivy;
    case 'anthurium':
      return needsWater ? flamingoFlowerSad : flamingoFlower;
    case 'dypsis lutescens':
      return needsWater ? arecaPalmSad : arecaPalm;
    case 'calathea sanderiana':
      return needsWater ? calatheaSad : calathea;
    case 'dracena marginata':
      return needsWater ? dracenaSad : dracena;
    case 'kalanchoe':
      return needsWater ? kalanchoeSad : kalanchoe;
    case 'agave':
      return needsWater ? mangaveSad : mangave;
    case 'philodendron':
      return needsWater ? monsteraSad : monstera;
    case 'chamaedorea elegans':
      return needsWater ? parlorPalmSad : parlorPalm;
    case 'peperomia':
      return needsWater ? peperomiaSad : peperomia;
    case 'selenicereus undatus':
      return needsWater ? dragonFruitSad : dragonFruit;
    
    case 'ananas comosus':
      return needsWater ? ananasSad : ananas;
    case 'persea americana':
      return needsWater ? avocadoSad : avocado;
    case 'calla palustris':
      return needsWater ? happyCallaSad : happyCalla;
    
    case 'hibiscus rosa-sinensis':
      return needsWater ? hibiscusSad : hibiscus;
    case 'chlorophytum comosum':
      return needsWater ? spiderPlantSad : spiderPlant;
    case 'tradescantia':
      return needsWater ? tradescantiaSad : tradescantia;
    case 'saintpaulia':
      return needsWater ? violetSad : violet;
    case 'pilea peperomioides':
      return needsWater ? chineseMoneyPlantSad : chineseMoneyPlant;      
    case 'streptocarpus':
      return needsWater ? streptocarpusSad : streptocarpus;
    default:
      return needsWater ? fallbackSad : fallback;
      
  }
};

function PlantImage({ scientificName, needsWater = false }: PlantImageProps) {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const selectedImage = imageError ? fallback : getPlantImage(scientificName, needsWater)

  return (
    <img 
      src={selectedImage} 
      alt="plant image" 
      className={styles.image}
      onError={handleImageError}
    />
  );
}

function WhenToWater({ daysUntilNextWatering }: WhenToWaterProps) {
  if(daysUntilNextWatering === 1)
    return (
      <div className="text-center text-muted"><small className="">Day to water</small></div>
    )

  if(daysUntilNextWatering < 1)
    return (
      <div className="text-center"><small>Water me!</small></div>
    )

  return (
    <div className="text-center text-muted"><small>Days to water</small></div>
  )
}

function Plant({ plant, index, room }: PlantProps) {
    const userContext = useContext(UserContext);

    const dispatch = usePlantsDispatch()

    const [isEditMode, setIsEditMode] = useState(false);
    const toggleEditMode = () => setIsEditMode(!isEditMode);

    const [isEditModal, setIsEditModal] = useState(false);
    const toggleEditModal = () => setIsEditModal(!isEditModal);

    const [waterPlant, wateringStatus] = useMutation(WATER_PLANT, {
      onCompleted: (data: { waterPlant: PlantData }) => {
        dispatch({ type: 'update', plant: data.waterPlant.plant, index: index })
      },
      onError: (e) => console.error('Error creating plant:', e)
    });

    const [postponeWatering, postponeWateringStatus] = useMutation(POSTPONE_WATERING, {
      onCompleted: (data: { postponeWatering: PlantData }) => {
        dispatch({ type: 'update', plant: data.postponeWatering.plant, index: index })
      },
      onError: (e) => console.error('Error postponing watering plant:', e)
    });


    const toWater = () => waterPlant({variables: { plantId: plant.id } });
    const toPostponeWatering = () => postponeWatering({variables: {plantId: plant.id, postponeDays: 1}})

    const daysToWatering = plant.daysUntilNextWatering
    const daysBetweenWatering = plant.daysBetweenWatering + plant.daysPostpone
    // TODO move functions to utils and consts to localstate values

    const getPlantHealth = (daysToWatering: number, daysBetweenWatering: number): number => {
      if(daysToWatering > 0) {
        return (daysToWatering / daysBetweenWatering)*100;
      }
      return 0;
    }

    const plantHealth = getPlantHealth(daysToWatering, daysBetweenWatering);

    const isBorrowedPlant = (owner: string, currentUser: string) => owner !== currentUser

    const borrowedStyle = isBorrowedPlant(plant.owner.username, userContext.username) ? styles.borrowedPlant : ""

    const getHealthColor = (daysToWatering: number, daysBetweenWatering: number) => {
      const plantHealth = getPlantHealth(daysToWatering, daysBetweenWatering)
      if( plantHealth >= 70) {
          return "success"
        } else if ( plantHealth >= 50 ) {
          return "info"
        } else if ( plantHealth > 20 ) {
          return "warning"
        } else {
          return "danger"
        }
    }

    const healthColor = getHealthColor(daysToWatering, daysBetweenWatering)

    const getBackgroundColor = (daysToWatering: number, daysBetweenWatering: number) => {
      const plantHealth = getPlantHealth(daysToWatering, daysBetweenWatering)
      if( plantHealth >= 70) {
          return "success"
        } else if ( plantHealth >= 50 ) {
        return "info"
        } else if ( plantHealth > 20 ) {
          return "warning"
        } else if ( daysToWatering > 0) {
          return "danger"
        } else {
          return "alarm"
        }
    }

    const bgColor = getBackgroundColor(daysToWatering, daysBetweenWatering)
/*
    const onDragStartEvent: React.DragEventHandler = (event) => {
      // @ts-ignore
      event.currentTarget.style.border = "1px dashed rgba(0,0,0,.125)";
      event.currentTarget.classList.add(styles.draggedPlant)
      event.dataTransfer.effectAllowed = "copyMove";
    }

    const onDragEndEvent: React.DragEventHandler = (event) => {
      // @ts-ignore
      event.currentTarget.style.border = "1px solid rgba(0,0,0,.125)";
      event.currentTarget.classList.remove(styles.draggedPlant);
    }
*/

    const isSomethingLoading = (actions: MutationResult<any>[]) =>
      actions.some(action => action.loading)

    if (isEditMode)
      return (
        <Card className={styles.plant}>
          <Badge color="light">{plant.symbol.userWideId}</Badge>
          <CardBody>
            <PlantsEdit plant={plant} index={index} action={toggleEditMode}/>
          </CardBody>
        </Card>
      )

    const color = room?.colorBackground;

    return (
      <Card
            className={`${styles.plant} ${styles[bgColor]} ${borrowedStyle}`} id={plant.id+""} data-testid={`plant-card-${index}`}
            style={{borderColor: color, backgroundImage: `url(${getPlantImage(plant.scientificName, daysToWatering <= 0)})`   }}
            >
        <Badge color="dark" className={styles.badge}>#{plant.symbol.userWideId}</Badge>
        <CardBody className={styles.narrowCard}>
          <section className={styles.imageGroup}>
            <WhenToWater daysUntilNextWatering={daysToWatering}/>
            <ButtonGroup className={styles.progressGroup}>
              <Progress value={ plantHealth } color={ healthColor } className={styles.progressBar}>{daysToWatering}</Progress>
              <Button size="sm" color="primary" onClick={toPostponeWatering} title="Postpone Watering">+1</Button>
            </ButtonGroup>
          </section>
          <section className={styles.actions}>
            <Button size="sm" outline onClick={toWater} title="Water" data-testid="water-btn" className={uiStyles.roundButton}>&#128166;</Button>
            <PlantImage 
              scientificName={plant.scientificName} 
              needsWater={daysToWatering <= 0}
            />
            <Button size="sm" outline onClick={toggleEditModal} title="Edit" data-testid="edit-btn" className={uiStyles.roundButton}>
              <i className="icon icon-settings" />
            </Button>
          </section>
          <CardTitle tag="h5">{plant.name}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">{plant.scientificName}</CardSubtitle>
        </CardBody>
        <RoomBadge room={room}></RoomBadge>
        <EditModal isOpen={isEditModal} toggleAction={toggleEditModal} index={index} plant={plant}/>
        <ErrorHandler error={wateringStatus.error} />
        <LoadingScreen isLoading={isSomethingLoading([wateringStatus, postponeWateringStatus])} isFullScreen={false}/>
      </Card>
    )
}

export { Plant }
